import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  AppBackButton,
  AppHeading,
  ClaimStatus,
  DocumentView,
} from "../../components/AppComponents";
import Main from "../Main";
import { ApiGet, ApiPost, setError } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import PatientDetails from "./components/PatientDetails";
import HospitalDetails from "./components/HospitalDetails";
import PaymentDetails from "./components/PaymentDetails";
import LabelHeading from "./components/LabelHeading";
import { Link } from "react-router-dom";

const ClaimDetail = (props) => {
  const [claim, setClaim] = useState([]);
  const BasicBtn = useRef(null);

  const getClaim = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`claim/${id}`);
    if (response.status === "success") {
      setClaim(response.data);
      if (response?.data?.claim_is_new) {
        await ApiPost("add-claim-notification", {
          claim_status: response.data.status,
          claim_id: response.data.claim_id,
        });
      }
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  useEffect(() => {
    const id = props?.match?.params?.claimId;
    getClaim(id);
    window.scrollTo(0, 0);
  }, [props.match.params.claimId]);
  return (
    <Main MessageRef={BasicBtn}>
      <AppBackButton to="/reimbursements" label="Back to Reimbursements" />
      <div className="w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
        <AppHeading
          className="pl-4 text-center font-medium"
          style={{ color: "#DB2228" }}
        >
          Reimbursement Details
        </AppHeading>
        <div>
          <div className="flex items-center">
            <LabelHeading label="Reimbursement status :" />
            <p
              style={{
                display: "inline-block",
                padding: "2px 5px",
                color: "white",
                borderRadius: "5px",
                fontWeight: "600",
                marginTop: "5px",
              }}
            >
              <ClaimStatus
                status={claim.status}
                style={{ fontSize: "16px", padding: "5px 8px" }}
              />
            </p>
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <PatientDetails claim={claim} />
            <HospitalDetails claim={claim} />
            <PaymentDetails claim={claim} />
          </div>
        </div>

        <hr />
        <LabelHeading label="Remarks" />
        <div className="flex items-center justify-between">
          <div style={{ width: "80%" }}>
            {claim?.claim_reject_reason && (
              <div>
                <span className="text-sm ">Reason for rejection: </span>
                <span className="font-semibold">
                  &nbsp;{claim?.claim_reject_reason}
                </span>
              </div>
            )}
            {claim?.sub_claims?.[0]?.remarks && (
              <div>
                <span className="text-sm ">Remark: </span>
                <span className="font-semibold">
                  &nbsp;{claim?.sub_claims?.[0]?.remarks}
                </span>
              </div>
            )}
            {!claim?.claim_reject_reason &&
              !claim?.sub_claims?.[0]?.remarks && (
                <p className="text-center text-sm">No remarks found</p>
              )}
          </div>
          {/* {(claim.status === "REJECTED" || claim.status === "DISPUTE") && (
            <div>
              <Link
                className="text-xs mr-3 bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover"
                to={{
                  pathname: "/reimbursements",
                  state: { item: claim },
                }}
              >
                {claim && claim?.sub_claims?.[0]?.raised_ticket === "1"
                  ? "ADD COMMENT"
                  : "RAISED DISPUTE"}
              </Link>
            </div>
          )} */}
          {(claim?.status === "QUERY" || claim?.status === "DRAFT") && (
            <div>
              <Link
                className="text-xs mr-3 bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover"
                to={{
                  pathname: `/reimbursementEdit/${claim?.claim_id}/${claim?.sub_claims[0]?.["sub_claim_id"]}`,
                  // state: { item: claim },
                }}
              >
                EDIT
              </Link>
            </div>
          )}
        </div>
        <hr />

        <LabelHeading label="Uploaded documents" />

        {claim?.documents && claim?.documents.length > 0 ? (
          <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
            {claim.documents.map((item) => (
              <div className="col-span-1" key={item.id}>
                <DocumentView item={item} status={item.status} />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-sm">No report found</p>
        )}
        <hr />
      </div>
    </Main>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

export default withRouter(connect(mapStateToProps)(ClaimDetail));
