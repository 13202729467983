import React, { useCallback, useState } from "react";
import Button from "../../components/Button";
import { getApiClaim, uploadClaimDocuments } from "../../lib/AppHelper";
import { inputTypes } from "./inputTypes";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";
import { downloadBase64Data } from "../../components/AppComponents";
import ImageView from "./ImageView";
import Compressor from "compressorjs";

const ImageType = ["image/jpeg", "image/jpg", "image/png"];
export const HealthDocuments = ({
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  columns,
  onRemove,
  handleSubmitRecord,
}) => {
  const [errors, setErrors] = useState({});

  const onClick = useCallback(() => {
    let error = 0;
    columns.map((column) => {
      const { id } = column;
      if (!state[`doc_${id}`] || state[`doc_${id}`].length == 0) {
        error = error + 1;
      }
    });
    setTimeout(() => {
      if (error != columns.length) {
        setErrors({});
        return onSubmit();
      } else {
        toast.error("please upload any one document");
      }
    }, 100);
  }, [errors, columns, state]);

  const compressDocument = (doc) => {
    return new Promise((resolve, reject) => {
      new Compressor(doc, {
        quality: 0.6, // Adjust quality as per your requirement
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };
  const updateDocuments = useCallback(
    async (id, files) => {
      let previousDocuments = state?.[`doc_${id}`]?.length || 0;
      if (previousDocuments + files?.length > 3) {
        toast.warning("maximum image can be upto 3");
        return;
      }

      const compressedFiles = [];
      for (let i = 0; i < files.length; i++) {
        const value = files[i];
        const fileSize = value?.size;
        let mimeType = value?.["type"];
        if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
          toast.warning("Only images or PDFs are allowed");
          return;
        } else if (fileSize > 30000000 && mimeType == "application/pdf") {
          toast.warning("Pdf size should not be more than 30 MB");
          return;
        } else if (mimeType == "application/pdf") {
          compressedFiles.push(files[i]);
        } else {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const compressedDoc = await compressDocument(files[i]);
          AppStore.dispatch({ type: "LOADING", loading: false });
          const file = new File([compressedDoc], compressedDoc.name, {
            type: compressedDoc.type,
            lastModified: Date.now(),
          });
          compressedFiles.push(file);
        }
      }
      AppStore.dispatch({ type: "LOADING", loading: true });
      await uploadClaimDocuments(id, state.claimId, compressedFiles).then(
        async (res) => {
          if (res?.length) {
            const response = await getApiClaim(
              `docsByReferenceId/${state?.claimId}/${id}`
            );
            setValue((prevState) => {
              const documents = response?.map((doc) => {
                return {
                  docId: id,
                  id: doc?.document?.id,
                  blob: downloadBase64Data(
                    doc?.encodedResource,
                    doc?.document?.documentName,
                    doc?.mimeType,
                    false
                  ),
                };
              });
              return {
                ...prevState,
                [`doc_${id}`]: documents,
              };
            });
          }
        }
      );
      AppStore.dispatch({ type: "LOADING", loading: false });
    },
    [state]
  );
  return (
    <div>
      <p style={{ fontWeight: "600", color: "#Db2228", marginBottom: "10px" }}>
        Upload claim documents
      </p>
      {columns?.map((column) => {
        const { type, id, details } = column;
        let RenderComponent = inputTypes["file"];
        return (
          <div className="flex">
            <RenderComponent
              key={id}
              label={type}
              error={[errors[id]]}
              details={details}
              onRemove={onRemove}
              field={`doc_${id}`}
              isShowStatus={false}
              isShowButton={state[`doc_${id}`]?.length > 0}
              docID={id}
              attrs={{
                multiple: state.forUpdate ? true : false,
                onChange: (e) => {
                  updateDocuments(id, Array.from(e?.target?.files));
                  e.target.value = null;
                },
                style: { height: "215px" },
              }}
            />
            <div className="flex flex-wrap">
              {state[`doc_${id}`]?.length > 0 &&
                state[`doc_${id}`]?.map((doc, index) => {
                  if (!doc?.blob) return;
                  return (
                    <ImageView
                      doc={doc?.blob}
                      index={index}
                      onRemove={() =>
                        setValue((prev) => ({ ...prev, open: doc }))
                      }
                      id={id}
                    />
                  );
                })}
            </div>
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-700 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              //   ref: this.BasicBtn,
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
