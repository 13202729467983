import React, { memo } from "react";
import "rsuite-table/lib/less/index.less";
import { Pagination } from "rsuite";
import { Link } from "react-router-dom";
import {
  ACTION_TOGGLE,
  AMOUNT_TOGGLE,
  TimerCount,
  OueryTimerCount,
  SettledTimerCount,
  formatNumber,
  getMessage,
  getMessageForSettled,
  truncateString,
} from "./constant";
import { ClaimStatus } from "../../../components/AppComponents";
import { Tooltip } from "@mui/material";
import useClaimsPagination from "../hook/useClaimsPagination";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Menu, Typography, Button } from "@mui/material";
import { ApiPost } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";
import { Modal } from "rsuite";

const statusColors = {
  IN_PROGRESS: "#CC7822",
  PENDING: "#CC7822",
  APPROVED: "#CC7822",
  QUERY: "#F43985",
  SETTLED: "#008000",
  REJECTED: "#A83CFD",
  DRAFT: "#6E6E6E",
};

const DataTable = memo(
  ({
    request,
    data,
    limit = 10,
    pagination = true,
    filterValues = {},
    loading,
    endPoint,
    extraData,
    renderItem,
    dataType,
    handleGetSearch,
    pageType = "",
    permissions,
    dateFilter,
    filter,
    setOpen,
    fetchAPIData,
    fetchCardsCounts,
    fetchCounts,
  }) => {
    const {
      tableData,
      totalCount,
      page,
      errorMessage,
      handleChangePage,
      fetchData,
      changeSortDirection,
      sortBy,
    } = useClaimsPagination({
      request,
      data,
      limit,
      filterValues,
      endPoint,
      extraData,
      handleGetSearch,
      dataType,
      filter,
      dateFilter,
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [claimToDelete, setClaimToDelete] = React.useState(null);

    const handleClick = (event, type) => {
      setAnchorEl(event?.currentTarget);
      setOpenMenu(type);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpenMenu(false);
    };

    const handleSort = () => {
      changeSortDirection("claim_register_date");
    };

    React.useEffect(() => {
      if (fetchAPIData) {
        fetchAPIData.current = fetchData;
      }
    }, [fetchAPIData, fetchData]);

    React.useEffect(() => {
      if (filter !== "DRAFT") {
        setSelectedIds([]);
      }
    }, [filter]);

    const handleCheckboxChange = (e, claimId) => {
      if (e.target.checked) {
        setSelectedIds((prevSelectedIds) => [...prevSelectedIds, claimId]);
      } else {
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter((id) => id !== claimId)
        );
      }
    };

    const handleDeleteForDraft = async () => {
      const payload = claimToDelete
        ? { claimId: [claimToDelete] }
        : { claimId: selectedIds };

      AppStore.dispatch({ type: "LOADING", loading: true });

      const response = await ApiPost("claims-delete", payload);
      if (response.code === 200) {
        toast.success(response?.message);
        setSelectedIds([]);
        fetchData(1);
        fetchCardsCounts();
        fetchCounts();
      } else {
        toast.error(response?.message);
      }

      AppStore.dispatch({ type: "LOADING", loading: false });
      setModalVisible(false);
      setClaimToDelete(null);
    };

    const showDeleteConfirmation = (claimId = null) => {
      setClaimToDelete(claimId);
      setModalVisible(true);
    };

    const closeModal = () => {
      setModalVisible(false);
      setClaimToDelete(null);
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        className="mt-2"
      >
        {filter === "DRAFT" && (
          <div className="text-xs font-normal">
            <div className="mb-3" style={{ color: "#db2228" }}>
              Incomplete forms that have been in Draft for more than 30 days
              will be automatically deleted.
            </div>
            {selectedIds?.length > 0 && (
              <div
                className="text-gray-900 py-2 px-4 mb-3"
                style={{ background: "#EFEFEF", cursor: "default" }}
              >
                <div className="flex justify-between">
                  <span className="pr-1">
                    ({selectedIds?.length}){" "}
                    {selectedIds?.length === 1 ? "item" : "items"} selected for
                    delete.
                  </span>
                  <RiDeleteBin6Line
                    className="cursor-pointer"
                    fontSize="18px"
                    color="#DB2228"
                    onClick={() => showDeleteConfirmation()}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col hidden md:block">
          <div className="overflow-x-auto">
            <table className="min-w-full border" id="table-to-xls">
              <thead className="bg-gray2 text-white h-8">
                {filter === "DRAFT" && (
                  <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          // Select all rows
                          setSelectedIds(
                            tableData.map((item) => item.claim_id)
                          );
                        } else {
                          // Deselect all rows
                          setSelectedIds([]);
                        }
                      }}
                      checked={
                        tableData?.length > 0 &&
                        selectedIds?.length === tableData?.length
                      }
                      disabled={tableData?.length === 0}
                    />
                  </th>
                )}
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  ID
                </th>
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  Name
                </th>
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  Status
                </th>
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  Loan code /<br />
                  reference number
                </th>
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  Registered date{" "}
                  <span onClick={handleSort} className="cursor-pointer">
                    {sortBy === "asc" ? (
                      <Tooltip title="Sort in ascending order" arrow>
                        <ArrowUpwardIcon
                          style={{ color: "white", fontSize: 16 }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Sort in descending order" arrow>
                        <ArrowDownwardIcon
                          style={{ color: "white", fontSize: 16 }}
                        />
                      </Tooltip>
                    )}
                  </span>
                </th>
                <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                  Reimbursement type
                </th>
                {AMOUNT_TOGGLE.includes(filter) && (
                  <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                    Reimbursement <br />
                    amount
                  </th>
                )}
                {!(filter === "REJECTED" || filter === "DISPUTE") &&
                  ACTION_TOGGLE.includes(filter) && (
                    <th className="text-sm font-sm px-4 py-3 whitespace-nowrap text-left">
                      Action
                    </th>
                  )}
              </thead>
              <tbody>
                {tableData && tableData?.length ? (
                  tableData?.map((item, index) => {
                    let countTime = TimerCount(item?.claim_register_date);
                    let countTimeForOuery = OueryTimerCount(item?.modified_on);
                    let countTimeForSettled = SettledTimerCount(
                      item?.claim_register_date,
                      item?.sub_claims?.[0]?.transaction_date,
                      item?.claim_status
                    );
                    let new_start_date = item.start_date
                      ? moment(item.start_date).format("DD-MM-YYYY")
                      : "";

                    return (
                      <tr
                        className={`border-b-2 ${
                          item?.sub_claims[0]?.raised_ticket === "1" ? "" : ""
                        } ${index % 2 === 0 ? "" : "bg-tableColumn"}`}
                        key={index}
                      >
                        {filter === "DRAFT" && (
                          <td className="px-4 py-3 text-left">
                            <input
                              type="checkbox"
                              value={item.claim_id}
                              onChange={(e) =>
                                handleCheckboxChange(e, item.claim_id)
                              }
                              checked={selectedIds.includes(item.claim_id)}
                            />
                          </td>
                        )}
                        <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingLeft: "10px",
                              position: "relative",
                            }}
                          >
                            {(item?.claim_status === "SETTLED" ||
                              item?.claim_status === "REJECTED" ||
                              item?.claim_status === "DRAFT") && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-1px",
                                  left: "-5px",
                                  color: "#DB2228",
                                  fontWeight: "bolder",
                                  display: item?.claim_is_new
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {`\u25CF`}
                              </span>
                            )}

                            <Link
                              to={"/reimbursements/" + item.claim_id}
                              style={{
                                color: "#003CBC",
                                fontWeight: "400",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {item?.claim_id}
                            </Link>
                          </div>
                        </td>
                        <td
                          className="text-gray-900 px-4 py-3 whitespace-nowrap text-left"
                          style={{ overflowWrap: "break-word" }}
                        >
                          <div className="flex flex-col items-start">
                            <div className="flex items-left text-wrap capitalize">
                              {truncateString(item?.name?.toLowerCase(), 20)}
                            </div>
                          </div>
                        </td>
                        <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              <ClaimStatus
                                status={item.claim_status}
                                modifiedStyle={true}
                              />
                            </div>
                            <div>
                              {(item?.claim_status === "IN_PROGRESS" ||
                                item?.claim_status === "APPROVED") &&
                              countTime?.[0] !== 0 ? (
                                <Tooltip
                                  title={
                                    countTime?.length > 0
                                      ? getMessage(countTime[2])
                                      : ""
                                  }
                                  placement="top"
                                  followCursor
                                  arrow
                                >
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color:
                                        statusColors[
                                          item?.claim_status?.toUpperCase()
                                        ],
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "7px 5px 0px",
                                      }}
                                    >
                                      {countTime?.length > 0 && countTime[0]}{" "}
                                      {countTime?.length > 0 && countTime[1]}{" "}
                                      {countTime?.length > 0 &&
                                        Math.abs(countTime[2])}{" "}
                                      {countTime?.length > 0 && countTime[3]}{" "}
                                    </p>
                                  </div>
                                </Tooltip>
                              ) : item?.claim_status === "QUERY" ? (
                                item?.sub_claims?.length > 0 &&
                                countTimeForOuery?.[1] !== 0 && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color:
                                        statusColors[
                                          item?.claim_status?.toUpperCase()
                                        ],
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "7px 5px 0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleClick(e, item?.claim_id)
                                      }
                                    >
                                      <InfoOutlinedIcon
                                        style={{
                                          fontSize: "12px",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <span
                                        style={{ textDecoration: "underline" }}
                                      >
                                        Need more info
                                      </span>
                                    </p>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={openMenu === item?.claim_id}
                                      onClose={handleClose}
                                      slotProps={{
                                        paper: {
                                          elevation: 0,
                                          sx: {
                                            width: "350px",
                                            color: "#ffffff",
                                            padding: "8px 24px",
                                            borderTopLeftRadius: "0px",
                                            backgroundColor: "#94A3B8",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "24px",
                                            borderBottomLeftRadius: "24px",
                                            borderBottomRightRadius: "24px",
                                          },
                                        },
                                      }}
                                      transformOrigin={{
                                        horizontal: "left",
                                        vertical: "top",
                                      }}
                                      anchorOrigin={{
                                        horizontal: "left",
                                        vertical: "bottom",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          variant="caption"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "300",
                                            marginBottom: "5px",
                                            display: "block",
                                          }}
                                        >
                                          Remark
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {item?.sub_claims?.[0]?.remarks
                                            ? item?.sub_claims?.[0]?.remarks?.toLowerCase()
                                            : "--"}
                                        </Typography>
                                        {filter === "QUERY" && (
                                          <div className="flex justify-end">
                                            <Button
                                              style={{
                                                background: "#ffffff",
                                                borderRadius: "4px",
                                                padding: "4px 8px",
                                              }}
                                            >
                                              <span className="text-gray-900 capitalize">
                                                <Link
                                                  to={{
                                                    pathname: `reimbursementEdit/${item?.claim_id}/${item?.sub_claims[0]?.["sub_claim_id"]}`,
                                                    state: {
                                                      start_date:
                                                        new_start_date,
                                                    },
                                                  }}
                                                >
                                                  Edit
                                                </Link>
                                              </span>
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    </Menu>
                                  </div>
                                )
                              ) : item?.claim_status === "SETTLED" ? (
                                countTimeForSettled?.[2] !== 0 && (
                                  <Tooltip
                                    title={
                                      countTime?.length > 0
                                        ? getMessageForSettled(
                                            item?.claim_register_date,
                                            item?.sub_claims?.[0]
                                              ?.transaction_date,
                                            item?.claim_status
                                          )
                                        : ""
                                    }
                                    placement="top"
                                    followCursor
                                    arrow
                                  >
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color:
                                          statusColors[
                                            item?.claim_status?.toUpperCase()
                                          ],
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          padding: "7px 5px 0px",
                                        }}
                                      >
                                        {countTimeForSettled?.length > 0 &&
                                          countTimeForSettled[0]}{" "}
                                        {countTimeForSettled?.length > 0 &&
                                          countTimeForSettled[1]}{" "}
                                        {countTimeForSettled?.length > 0 &&
                                          Math.abs(countTimeForSettled[2])}{" "}
                                        {countTimeForSettled?.length > 0 &&
                                          countTimeForSettled[3]}{" "}
                                      </p>
                                    </div>
                                  </Tooltip>
                                )
                              ) : (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "7px 5px 0px",
                                    color: `${
                                      statusColors[
                                        item?.claim_status?.toUpperCase()
                                      ]
                                    }`,
                                  }}
                                >
                                  --
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                          {formatNumber(item?.loan_code)}
                        </td>
                        <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                          {item?.claim_register_date
                            ? moment(item?.claim_register_date).format(
                                "DD-MM-YYYY"
                              )
                            : "--"}
                        </td>
                        <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left capitalize">
                          {item?.specification_type.toLowerCase()}
                        </td>
                        {AMOUNT_TOGGLE.includes(filter) && (
                          <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                            {item?.bill_amount}
                          </td>
                        )}
                        {(filter === "QUERY" || filter === "DRAFT") && (
                          <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                            <Link
                              to={{
                                pathname: `reimbursementEdit/${item?.claim_id}/${item?.sub_claims[0]?.["sub_claim_id"]}`,
                                state: { start_date: new_start_date },
                              }}
                            >
                              <FaRegEdit fontSize="20px" />
                            </Link>
                          </td>
                        )}

                        {/* {(filter === "REJECTED" || filter === "DISPUTE") && (
                          <td className="text-gray-900 px-4 py-3 whitespace-nowrap text-left">
                            <div className="flex flex-col items-center gap-1">
                              <Tooltip
                                title="a new comment is added"
                                placeholder="left"
                                arrow
                              >
                                <span
                                  style={{
                                    background: "#DB2228",
                                    color: "white",
                                    fontWeight: "600",
                                    padding: "0px 3px",
                                    fontSize: "8px",
                                    borderRadius: "2px",
                                    width: "80px",

                                    display: !item?.openComment
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  New comment
                                </span>
                              </Tooltip>
                              <Tooltip
                                title="a new comment is added"
                                placeholder="left"
                                arrow
                              >
                                <span
                                  style={{
                                    background: "#008000",
                                    color: "white",
                                    fontWeight: "600",
                                    padding: "0px 3px",
                                    fontSize: "8px",
                                    borderRadius: "2px",
                                    width: "80px",
                                    display:
                                      item?.sub_claims[0]?.raised_ticket ===
                                        "1" && "block",
                                  }}
                                >
                                  {item?.sub_claims[0]?.raised_ticket === "1" &&
                                    "In Dispute"}
                                </span>
                              </Tooltip>
                              <button
                                className="text-xs bg-transparent font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded pb-2 button-hover icon"
                                style={{ width: "130px" }}
                                onClick={() => setOpen(item)}
                              >
                                {item?.sub_claims[0]?.raised_ticket === "1"
                                  ? "ADD COMMENT"
                                  : "RAISE DISPUTE"}
                              </button>
                            </div>
                          </td>
                        )} */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      height="50"
                      className="text-red-500 font-light text-center border"
                    >
                      Your result is empty
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-full block sm:block md:hidden justify-center">
          {tableData &&
            tableData?.length >= 0 &&
            tableData?.map((item, index) => {
              if (renderItem) {
                return renderItem(item);
              }
            })}
        </div>

        {pagination && tableData && tableData?.length > 0 ? (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={limit}
              activePage={page}
              onChangePage={handleChangePage}
            />
          </div>
        ) : null}

        <Modal
          open={isModalVisible}
          onClose={closeModal}
          maxWidth="md"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Body>
            Are you sure you want to delete ({selectedIds?.length}){" "}
            {selectedIds?.length === 1 ? "item" : "items"} ?
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-center gap-1 mt-2">
              <Button
                onClick={handleDeleteForDraft}
                style={{
                  backgroundColor: "#e23744",
                  color: "#ffffff",
                  margin: "8px",
                  borderRadius: "6px",
                }}
              >
                Yes
              </Button>
              <Button
                onClick={closeModal}
                style={{
                  backgroundColor: "#374151",
                  color: "#ffffff",
                  margin: "8px",
                  borderRadius: "6px",
                }}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
);

export default DataTable;
