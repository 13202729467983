import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AmountText, AppHeading } from "../components/AppComponents";
import {
  DaughterIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "../components/AppIcons";
import Button from "../components/Button";
import { InputCounter } from "../components/form/InputCounter";
import InputGroup from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import { InputToggle } from "../components/form/InputToggle";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import paymentImg from "./../payment-img.png";
import { ArrowLeftIcon, DuplicateIcon } from "@heroicons/react/outline";
import {
  ApiGet,
  ApiPost,
  getResellerLanguage,
  getRsellerPinCode,
  setMessage,
  validateEmail,
  validateMobile,
  validatePolicyPuchDate,
} from "../lib/AppHelper";
import FamilyDetails from "./subscription/FamilyDetails";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { trimObjectValues } from "../utils/constants";
import {
  genderMatch,
  governmentIdMatch,
  govtIds,
} from "../services/common/data";
import TermsModal from "../components/form/TermsModal";

const PlanOption = ({
  title = "",
  existing,
  features = [],
  amount = "1,123",
}) => {
  return (
    <div className="bg-primary-50 border border-warning rounded-xl p-5">
      <p className="font-semibold">{title}</p>
      <ul className="list-disc pl-4 text-xs my-3">
        {features?.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      <div className="font-semibold">
        <AmountText amount={amount} />
      </div>
      {existing && <p className="text-red-500">Existing plan</p>}
    </div>
  );
};

export class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        max_members: 0,
        product: "",
        product_type: "",
        bookNow: false,
        language: getResellerLanguage() ? getResellerLanguage() : "Hindi",
        members: {
          holder: true,
          spouse: false,
          daughter: false,
          son: false,
          parents: false,
        },
        members_count: {
          daughter: 1,
          son: 1,
          sister: 1,
          parents: {
            self: 1,
            spouse: 0,
          },
          daughter_in_law: 1,
          mother_in_law: 1,
          sister_in_law: 1,
          son_in_law: 1,
          father_in_law: 1,
          brother_in_law: 1,
        },
        name: "",
        phone: "",
        email: "",
        gender: "m",
        dob: "",
        pincode: "",
        state: "",
        governmentIdType: "",
        governmentId: "",
        members_detail: {
          self: {
            height: null,
            weight: null,
          },
        },
        notExistingPlan: false,
        renew: false,
        renewal_date: "",
        old_application_id: "",
        age: 18,
      },
      answers: [],
      max: "",
      min: "",
      maxAge: "",
      minAge: "",
      otherMax: "",
      otherMin: "",
      step: 1,
      isEmailRequired: true,
      questions: [],
      answers: {},
      languages: [
        {
          label: "Hindi",
          value: "Hindi",
        },
        {
          label: "English",
          value: "English",
        },
        {
          label: "Marathi",
          value: "marathi",
        },
        {
          label: "Tamil",
          value: "Tamil",
        },
        {
          label: "Telugu",
          value: "Telugu",
        },
        {
          label: "Kannada",
          value: "Kannada",
        },
        {
          label: "Bengali",
          value: "Bengali",
        },
        {
          label: "Oriya",
          value: "Oriya",
        },
      ],
      eClinic: false,
      errors: {},
      open: false,
      retryCount: 0, // Initial retry count
      maxRetries: 3,
      isLabTestBook: false,
    };
  }

  steps = 5;
  BasicBtn = React.createRef();
  MessageRef = React.createRef();
  frontSide = React.createRef(null);
  backSide = React.createRef(null);
  timeout = React.createRef(null);
  checked_pin = "";

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isReady = () => {
    const {
      step,
      params,
      questions,
      errors,
      min,
      max,
      minAge,
      maxAge,
      isLabTestBook,
    } = this.state;

    const minAgeValue = moment(new Date()).format("YYYY-MM-DD");
    const maxAgeValue = moment().subtract(100, "years").format("YYYY-MM-DD");

    const selected_product = _.find(
      this.props.products,
      (item) => params.product == item.id
    );
    params.product_type = selected_product
      ? selected_product.product.product_type
      : "";

    if (selected_product) {
      params.max_members = selected_product.product.max_members;
    }

    if (step === 1 && !params.product) return false;

    if (
      step === 2 &&
      params.members.parents === true &&
      params.members_count.parents?.self +
        params.members_count.parents?.spouse ===
        0
    )
      return false;
    if (step === 2) {
      if (!selected_product) {
        return false;
      }
      const max_members = selected_product.product.max_members;
      const count = this.getSelectedCount();
      if (count > max_members) return false;
    }

    if (step === 3) {
      if (!params.name) {
        return false;
      }
      if (!params.phone || params.phone.length !== 10) return false;
      if (!isLabTestBook) {
        if (this.state.isEmailRequired) {
          if (!params.email || !this.validateEmail(params.email)) return false;
        }
        if (!params.governmentIdType) return false;
        if (!params.governmentId) return false;
        if (
          !params.dob ||
          !moment(params.dob).isValid() ||
          moment().diff(params.dob, "years") >= 100
        ) {
          return false;
        }
        if (!params.pincode || params.pincode.length !== 6) return false;
        if (!params.state) return false;
        if (
          validatePolicyPuchDate(
            params.dob,
            min || minAgeValue,
            max || maxAgeValue
          )
        )
          return false;
      }
    }

    if (step === 4) {
      const members_details = params.members_detail;
      let invalid = false;
      let count = 0;
      if (Object.keys(members_details)?.length == 1) {
        count = 1;
      }
      if (!max && !min) {
        count = 1;
      }

      _.forEach(members_details, (members_detail) => {
        if (
          members_detail.govtIDType != "" &&
          members_detail.govtIDNumber == ""
        ) {
          invalid = true;
          return false;
        } else if (
          members_detail.govtIDType == "" &&
          members_detail.govtIDNumber != ""
        ) {
          invalid = true;
          return false;
        } else if (members_detail.name == "") {
          invalid = true;
          return false;
        } else if (members_detail.dob == "") {
          invalid = true;
          return false;
        } else if (members_detail.gender == "") {
          invalid = true;
          return false;
        } else if (
          members_detail.coApplicant === true &&
          maxAge &&
          minAge &&
          validatePolicyPuchDate(
            members_detail.dob,
            moment().subtract(minAge, "years").format("YYYY-MM-DD"),
            moment().subtract(maxAge, "years").format("YYYY-MM-DD")
          )
        ) {
          invalid = true;
          return false;
        } else if (
          (!members_detail.coApplicant ||
            members_detail.coApplicant == false) &&
          validatePolicyPuchDate(members_detail.dob, minAgeValue, maxAgeValue)
        ) {
          invalid = true;
          return false;
        }
        if (members_detail.coApplicant === true) {
          count = count + 1;
        }

        if (invalid) return false;
      });
      if (!count) {
        invalid = true;
      }

      if (invalid) return false;
    }

    if (step === 5) {
      let yes = false;
      _.forEach(questions, (question) => {
        if (question.mandatory && !question.answer) yes = true;
        if (question?.answer === "1") yes = true;
      });
      if (yes) return false;
    }

    return true;
  };

  getSelectedCount = () => {
    const { params } = this.state;
    let count = 0;
    for (let prop in params.members) {
      if (params.members[prop] === true) {
        if (prop in params.members_count) {
          if (prop === "parents") {
            count = count + params.members_count[prop].self;
            count = count + params.members_count[prop].spouse;
          } else {
            count = count + params.members_count[prop];
          }
        } else {
          count = count + 1;
        }
      }
    }
    return count;
  };

  render() {
    const { products } = this.props;
    const {
      params,
      step,
      questions,
      answers,
      languages,
      eClinic,
      errors,
      open,
      otherMax,
      otherMin,
      maxAge,
      minAge,
      min,
      max,
      isLabTestBook,
    } = this.state;
    const selected_count = this.getSelectedCount();
    const selected_product = _.find(
      products,
      (item) => params.product === item.id
    );

    const ready = this.isReady();
    const items = [];

    _.each(products, (prod) => {
      let spec = [];
      const prodSpec = prod?.product?.specification;
      const description = prod?.product?.description;
      const cleanDescription = description
        ? description
            ?.replace(/<\/?li>/g, "")
            ?.split("\n")
            ?.map((item) => item.trim())
        : [];
      if (!prod?.product?.product_lab_test?.auto_book) {
        spec.push(`${prod.product?.max_members} Members`);
      }
      if (prodSpec !== "CLOAN")
        items.push({
          value: prod.id,
          label: (
            <PlanOption
              amount={prod.price}
              title={prod.name}
              existing={
                prod.product_id ==
                this.props?.history?.location?.state?.product_id
                  ? true
                  : false
              }
              features={[
                ...spec,
                ...cleanDescription,
                ..._.map(prod.inclusions, "title"),
              ]}
            />
          ),
        });
    });

    return (
      <Main MessageRef={this.MessageRef}>
        {this.props.products.length === 1 && step < 3 ? (
          false
        ) : step > 1 ? (
          <div
            onClick={this.prev}
            className="flex items-center text-primary pb-3 cursor-pointer"
          >
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{"Back"}</p>
          </div>
        ) : (
          false
        )}
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            {step === 1 ? (
              <div>
                <AppHeading>Select Plan</AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">Please select the product</h4> */}
                {params.notExistingPlan && (
                  <p className="text-red-500 my-1">
                    Your existing plan is currently not available. Please select
                    a new plan.
                  </p>
                )}
                <InputRadio
                  value={params.product}
                  buttonHidden={true}
                  attrs={{
                    onChange: (e) => {
                      this.onChangeHandler(e, "product");
                    },
                  }}
                  inline={false}
                  label={false}
                  options={items}
                />
              </div>
            ) : (
              false
            )}

            {step === 2 ? (
              <div>
                <AppHeading>
                  {selected_product ? selected_product.name : ""}
                </AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">
                  Please select family members
                </h4> */}

                <InputToggle
                  label="Primary Member"
                  value={true}
                  attrs={{}}
                ></InputToggle>

                <InputToggle
                  label="Spouse"
                  value={params.members.spouse}
                  attrs={{
                    onClick: () => {
                      if (!params.members_detail.spouse) {
                        this.setMemberChangeHandler(
                          { target: { value: !params.members.spouse } },
                          "members.spouse"
                        );
                      } else {
                        this.setMemberChangeHandler(
                          { target: { value: !params.members.spouse } },
                          "members.spouse"
                        );
                        this.removeDetails(
                          { target: { value: params.members_detail } },
                          "members.spouse"
                        );
                      }
                    },
                  }}
                >
                  <RingIcon className="mr-3" size={20} />
                </InputToggle>

                <div className="relative">
                  <InputToggle
                    label="Daughter"
                    value={params.members.daughter}
                    attrs={{
                      onClick: () => {
                        if (!params.members_detail.daughter) {
                          this.setMemberChangeHandler(
                            { target: { value: !params.members.daughter } },
                            "members.daughter"
                          );
                        } else {
                          this.setMemberChangeHandler(
                            { target: { value: !params.members.daughter } },
                            "members.daughter"
                          );
                          this.removeDetails(
                            { target: { value: params.members_detail } },
                            "members.daughter"
                          );
                        }
                      },
                    }}
                  >
                    <DaughterIcon className="mr-3" size={20} />
                  </InputToggle>

                  {params.members.daughter ? (
                    <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                      <InputCounter
                        min={0}
                        max={3}
                        value={params.members_count.daughter}
                        onMinus={() => {
                          if (params.members_count.daughter - 1 === 0) {
                            this.setMemberChangeHandler(
                              { target: { value: !params.members.daughter } },
                              "members.daughter"
                            );
                            this.removeDetails(
                              { target: { value: params.members_detail } },
                              "members.daughter_1"
                            );
                          } else {
                            this.removeDetails(
                              { target: { value: params.members_detail } },
                              `members.daughter_${params.members_count.daughter}`
                            );
                            this.setMemberChangeHandler(
                              {
                                target: {
                                  value: params.members_count.daughter - 1,
                                },
                              },
                              "members_count.daughter"
                            );
                          }
                        }}
                        onPlus={() => {
                          this.setMemberChangeHandler(
                            {
                              target: {
                                value: params.members_count.daughter + 1,
                              },
                            },
                            "members_count.daughter"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div className="relative">
                  <InputToggle
                    label="Son"
                    value={params.members.son}
                    attrs={{
                      onClick: () => {
                        this.setMemberChangeHandler(
                          { target: { value: !params.members.son } },
                          "members.son"
                        );
                      },
                    }}
                  >
                    <SonIcon className="mr-3" size={20} />
                  </InputToggle>

                  {params.members.son ? (
                    <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                      <InputCounter
                        min={0}
                        max={3}
                        value={params.members_count.son}
                        onMinus={() => {
                          if (params.members_count.son - 1 === 0) {
                            this.setMemberChangeHandler(
                              { target: { value: !params.members.son } },
                              "members.son"
                            );
                            this.removeDetails(
                              { target: { value: params.members_detail } },
                              "members.son_1"
                            );
                          } else {
                            this.removeDetails(
                              { target: { value: params.members_detail } },
                              `members.son_${params.members_count.son}`
                            );
                            this.setMemberChangeHandler(
                              {
                                target: {
                                  value: params.members_count.son - 1,
                                },
                              },
                              "members_count.son"
                            );
                          }
                        }}
                        onPlus={() => {
                          this.setMemberChangeHandler(
                            { target: { value: params.members_count.son + 1 } },
                            "members_count.son"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div className="relative">
                  <InputToggle
                    label="Parents"
                    value={params.members.parents}
                    attrs={{
                      onClick: () => {
                        this.setMemberChangeHandler(
                          { target: { value: !params.members.parents } },
                          "members.parents"
                        );
                      },
                    }}
                  >
                    <ParentsIcon className="mr-3" size={20} />
                  </InputToggle>
                </div>

                {params.members.parents ? (
                  <div className="flex -mt-2 justify-between">
                    <div className="w-32">
                      <InputCounter
                        mini={false}
                        label="My Parents"
                        min={0}
                        max={2}
                        value={params.members_count.parents.self}
                        onMinus={() => {
                          this.removeDetails(
                            { target: { value: params.members_detail } },
                            `members.self_parent_${params.members_count.son}`
                          );
                          this.setMemberChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.self - 1,
                              },
                            },
                            "members_count.parents.self"
                          );
                        }}
                        onPlus={() => {
                          this.setMemberChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.self + 1,
                              },
                            },
                            "members_count.parents.self"
                          );
                        }}
                        className="border rounded-xl mt-2"
                      />
                    </div>
                    <div className="w-32">
                      <InputCounter
                        mini={false}
                        label="Spouse Parents"
                        min={0}
                        max={2}
                        value={params.members_count.parents.spouse}
                        onMinus={() => {
                          this.removeDetails(
                            { target: { value: params.members_detail } },
                            `members.spouse_parent_${params.members_count.son}`
                          );
                          this.setMemberChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.spouse - 1,
                              },
                            },
                            "members_count.parents.spouse"
                          );
                        }}
                        onPlus={() => {
                          this.setMemberChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.spouse + 1,
                              },
                            },
                            "members_count.parents.spouse"
                          );
                        }}
                        className="border rounded-xl mt-2"
                      />
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}

            {step === 3 ? (
              <div>
                <AppHeading className="mb-3">Primary Member Holder</AppHeading>
                <InputGroup
                  label="Primary Member Name"
                  requiredMark="true"
                  placeholder="Enter name..."
                  attrs={{
                    maxLength: 32,
                    value: params.name,
                    onChange: (e) => {
                      // name validation regex
                      if (
                        /^[a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        this.onChangeHandler(e, "name");
                      }
                    },
                  }}
                  validateError={(value) => {
                    if (value) {
                      return false;
                    } else {
                      return "Please enter name";
                    }
                  }}
                />
                <InputGroup
                  label="Phone no"
                  prefix="+91"
                  placeholder="Enter phone number..."
                  requiredMark="true"
                  type="tel"
                  error={errors?.phone}
                  attrs={{
                    value: params.phone,
                    onChange: (e) => {
                      this.setState({
                        errors: { ...errors, phone: "" },
                      });
                      const integerRegex = /^-?\d+$/;
                      const isValidInteger = integerRegex.test(e.target.value);
                      if (isValidInteger || e.target.value == "") {
                        this.onChangeHandler(
                          {
                            target: { value: e.target.value.replace(/\D/, "") },
                          },
                          "phone"
                        );
                      }
                    },
                    maxLength: 10,
                  }}
                  validateError={(value) => {
                    if (validateMobile(value)) {
                      return false;
                    } else {
                      this.setState({
                        errors: {
                          ...errors,
                          phone: ["Please enter valid mobile number"],
                        },
                      });
                      return false;
                    }
                  }}
                />
                {this.state.isEmailRequired && !isLabTestBook ? (
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    requiredMark={isLabTestBook ? false : true}
                    error={errors?.email}
                    attrs={{
                      value: params.email,
                      onChange: (e) => {
                        this.setState({
                          errors: { ...errors, email: "" },
                        });
                        this.onChangeHandler(e, "email");
                      },
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        this.setState({
                          errors: {
                            ...errors,
                            email: ["please enter correct email ID"],
                          },
                        });
                        return false;
                      }
                    }}
                  />
                ) : (
                  false
                )}

                {!isLabTestBook && (
                  <InputRadio
                    label="Gender"
                    requiredMark="true"
                    name="gender"
                    buttonHidden={false}
                    options={[
                      { label: "Male", value: "m" },
                      { label: "Female", value: "f" },
                      { label: "Others", value: "o" },
                    ]}
                    value={params.gender}
                    attrs={{
                      onChange: (e) => this.onChangeHandler(e, "gender"),
                    }}
                  />
                )}
                {!isLabTestBook && (
                  <InputGroup
                    label={`Date of Birth ${
                      minAge && maxAge
                        ? `(min age ${minAge}, max age
                        ${maxAge})`
                        : ""
                    }`}
                    requiredMark="true"
                    placeholder="DOB"
                    attrs={{
                      type: "date",
                      value: params.dob,
                      onChange: (e) => this.onChangeHandler(e, "dob"),
                      min: this.state.max,
                      max: this.state.min
                        ? this.state.min
                        : moment().format("YYYY-MM-DD"),
                    }}
                    valdiateOnChange={true}
                    validateError={(value) => {
                      const minAgeValue = moment(new Date()).format(
                        "YYYY-MM-DD"
                      );
                      const maxAgeValue = moment()
                        .subtract(100, "years")
                        .format("YYYY-MM-DD");
                      if (
                        validatePolicyPuchDate(
                          value,
                          min || minAgeValue,
                          max || maxAgeValue
                        )
                      ) {
                        return validatePolicyPuchDate(
                          value,
                          min || minAgeValue,
                          max || maxAgeValue
                        );
                      } else {
                        return false;
                      }
                    }}
                  />
                )}

                {!isLabTestBook ? (
                  <InputSelect
                    requiredMark={isLabTestBook ? false : true}
                    label="Govt ID Type"
                    width="full"
                    attrs={{
                      value: params.governmentIdType,
                      onChange: (e) => {
                        e.target.value === ""
                          ? this.onChangeHandler(e, "governmentId")
                          : this.onChangeHandler(e, "governmentIdType");
                        this.onChangeHandler(e, "governmentIdType");
                      },
                    }}
                    options={govtIds}
                  ></InputSelect>
                ) : null}

                {!isLabTestBook ? (
                  <InputGroup
                    label="Govt ID Number"
                    placeholder="Enter Govt ID Number..."
                    requiredMark={isLabTestBook ? false : true}
                    attrs={{
                      disabled: params.governmentIdType === "",
                      value: params.governmentId,
                      onChange: (e) => {
                        if (
                          /^[a-zA-Z0-9\-]*$/.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (this.state.params.governmentIdType === "aadhar") {
                            if (e.target.value.length < 13) {
                              this.onChangeHandler(
                                {
                                  target: {
                                    value: e.target.value.replace(/\D/, ""),
                                  },
                                },
                                "governmentId"
                              );
                            }
                          } else if (
                            this.state.params.governmentIdType === "voter"
                          ) {
                            if (e.target.value.length < 15) {
                              this.onChangeHandler(e, "governmentId");
                            }
                          } else if (
                            this.state.params.governmentIdType === "passport"
                          ) {
                            if (e.target.value.length < 15) {
                              this.onChangeHandler(e, "governmentId");
                            }
                          } else if (
                            this.state.params.governmentIdType ===
                            "driving_licence"
                          ) {
                            if (e.target.value.length < 18) {
                              this.onChangeHandler(e, "governmentId");
                            }
                          } else {
                            if (e.target.value.length < 20) {
                              this.onChangeHandler(e, "governmentId");
                            }
                          }
                        }
                      },
                      maxLength: 20,
                    }}
                  />
                ) : null}

                {!isLabTestBook ? (
                  <InputGroup
                    label="Pincode"
                    placeholder="Enter Pincode..."
                    requiredMark={isLabTestBook ? false : true}
                    error={errors?.pin}
                    type="tel"
                    attrs={{
                      maxLength: 6,
                      value: params.pincode,
                      onChange: (e) => {
                        this.setState({
                          errors: { ...errors, pin: "" },
                        });
                        this.onChangeHandler(
                          {
                            target: { value: e.target.value.replace(/\D/, "") },
                          },
                          "pincode"
                        );
                        if (e.target.value.length == 6) this.checkPin();
                      },
                      onBlur: this.checkPin,
                    }}
                    onEnter={this.checkPin}
                  />
                ) : null}
                {params.state ? (
                  <InputGroup
                    label="Your State"
                    placeholder="Enter state..."
                    attrs={{ value: params.state, readOnly: true }}
                  />
                ) : (
                  false
                )}
                {eClinic && (
                  <InputSelect
                    requiredMark="true"
                    label="Language"
                    attrs={{
                      value: params.language,
                      onChange: (e) => {
                        this.onChangeHandler(e, "language");
                      },
                    }}
                    options={languages}
                  ></InputSelect>
                )}
              </div>
            ) : (
              false
            )}

            {step === 4 ? (
              <>
                <div>
                  <AppHeading>Member Details</AppHeading>
                  <h4 className="text-md mb-6 mt-6">
                    Please enter family details
                  </h4>
                  <FamilyDetails
                    value={params}
                    onChange={(v) =>
                      this.onChangeHandler(
                        { target: { value: v } },
                        "members_detail"
                      )
                    }
                    govt_ids={govtIds}
                    minAge={otherMin}
                    maxAge={otherMax}
                    showMinAge={minAge}
                    showMaxAge={maxAge}
                  />
                </div>
              </>
            ) : (
              false
            )}

            {step === 5 && questions.length > 0 ? (
              <div>
                <AppHeading>Questions</AppHeading>
                <h4 className="text-md mb-6 mt-6">
                  Please answer the following questions
                </h4>
                {questions.map((question, index) => {
                  return (
                    <InputRadio
                      key={question.medicalQuestionId}
                      label={question?.question}
                      name={`ques-${question?.medicalQuestionId}`}
                      hidden={false}
                      requiredMark={question.mandatory}
                      options={[
                        { label: "Yes", value: "1" },
                        { label: "No", value: "0" },
                      ]}
                      value={question?.answer}
                      attrs={{
                        onChange: (e) =>
                          this.onAnsChangeHandler(e, question, index),
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              false
            )}

            {step === 8 ? (
              <div>
                <AppHeading>Payment</AppHeading>
                <h4 className="text-md mb-6 mt-6">
                  Payment is sent to registered mobile number +91-9650320189
                </h4>
                <InputGroup
                  action={
                    <div
                      tabIndex={0}
                      className="focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none rounded-xl cursor-pointer text-primary shadow-sm border-primary focus:bg-primary-50 px-3 flex items-center justify-center  border ml-3"
                    >
                      <DuplicateIcon width={20} />
                    </div>
                  }
                  label="Payment link"
                  placeholder=""
                  attrs={{
                    value: "pytm.hsloremipsum/aiqa-health",
                    readOnly: true,
                  }}
                />
                <div className="flex mt-10 flex-col items-center">
                  <img
                    alt=""
                    className="w-40 h-40 rounded-full"
                    src={paymentImg}
                  />
                  <p className="text-gray-200 text-sm mb-2 mt-3">
                    PAGE EXPIRE IN
                  </p>
                  <p className="text-xl font-semibold">00:59 mins</p>
                  <Button
                    size="sm"
                    attrs={{
                      onClick: () => this.setState({ cancelPayment: true }),
                    }}
                    className="mt-10 max-w-min px-6"
                    outline={true}
                    title="Go Back"
                  >
                    <ArrowLeftIcon width={16} className="mr-2" />
                  </Button>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          <div style={{ marginTop: 5 }}>
            {params.max_members && selected_count > params.max_members ? (
              <p className="text-center text-xs font-semibold text-red-500 my-2">
                Maximum {params.max_members} members are allowed.
              </p>
            ) : (
              false
            )}
            <div>
              {this.state.eClinic ? (
                <Button
                  attrs={{
                    ref: this.BasicBtn,
                    disabled: !ready,
                    type: "button",
                    onClick: this.next,
                  }}
                  size="md"
                  title={step === 4 ? "Save and Continue" : "Continue"}
                />
              ) : (
                <Button
                  attrs={{
                    ref: this.BasicBtn,
                    disabled: !ready,
                    type: "button",
                    onClick: this.next,
                  }}
                  size="md"
                  title={step === this.steps ? "Save and Continue" : "Continue"}
                />
              )}
              {this.props.products.length === 1 && step < 3 ? (
                false
              ) : step > 1 ? (
                <button
                  onClick={this.prev}
                  className="w-full flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500"
                >
                  Back
                </button>
              ) : (
                false
              )}
            </div>
            <div className="text-sm text-center mt-6 cursor-pointer">
              <p
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                <span className="text-primary underline hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm px-1">
                  Terms &amp; Condition
                </span>
              </p>
            </div>
          </div>
        </div>
        <TermsModal open={this.state.open} handleClose={this.handleClose} />
      </Main>
    );
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  checkPin = async () => {
    if (!this.state.params.pincode) return;
    if (this.state.params.pincode === this.checked_pin) return;
    this.checked_pin = this.state.params.pincode;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`check-pin/${this.state.params.pincode}`);
    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.setState({ errors: response?.errors }, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
      this.onChangeHandler({ target: { value: null } }, "state");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  createDate = (years) => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - years);
    var ndate = new Date(date),
      mnth = ("0" + (ndate.getMonth() + 1)).slice(-2),
      day = ("0" + ndate.getDate()).slice(-2);
    return [ndate.getFullYear(), mnth, day].join("-");
  };

  removeDetails = (e, key) => {
    const objectKey = key.split(".");
    const { params } = this.state;
    delete params.members_detail[objectKey[1]];
    this.setState({ params: params });
  };

  onChangeHandler = (e, key) => {
    const { user } = this.props;
    const { permissions } = user;
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params }, () => {
      if (key === "product") {
        const selected_product = _.find(
          this.props.products,
          (item) => this.state.params.product === item.id
        );
        if (
          selected_product &&
          selected_product.mutual_aid &&
          this.steps !== 5
        ) {
          this.steps = 5;
          this.setProgress();
        } else if (
          selected_product &&
          !selected_product.mutual_aid &&
          this.steps !== 4
        ) {
          this.steps = 4;
          this.setProgress();
        }
      }
    });
    if (key === "product") {
      this.props.products.map((elem) => {
        if (elem.product_id == e.target.value) {
          this.setState({
            maxAge: elem?.getMinMaxAge?.max_age,
            minAge: elem?.getMinMaxAge?.min_age,
            isLabTestBook: elem.product?.product_lab_test?.auto_book,
          });
          if (elem?.getMinMaxAge?.min_age) {
            this.setState({
              min: this.createDate(elem?.getMinMaxAge?.min_age),
              otherMin: this.createDate(elem?.getMinMaxAge?.min_age),
            });
          }
          if (elem?.getMinMaxAge?.max_age) {
            this.setState({
              max: this.createDate(elem?.getMinMaxAge?.max_age),
              otherMax: this.createDate(elem?.getMinMaxAge?.max_age),
            });
          }

          this.setState({
            questions: [...elem.medicalQuestion],
            isEmailRequired: !elem.is_email_not_required,
          });

          if (
            (permissions?.appointment_booking || permissions?.aiqa_center) &&
            elem?.is_aiqa_center
          ) {
            let pinCode = getRsellerPinCode();
            this.setState({ eClinic: true });
            this.onChangeHandler({ target: { value: true } }, "bookNow");
            this.onChangeHandler({ target: { value: pinCode } }, "pincode");
            this.checkPin();
          } else {
            this.setState({ eClinic: false });
            this.onChangeHandler({ target: { value: false } }, "bookNow");
          }
        }
      });
    }
    if (params.members.spouse == false) {
      _.unset(params, "members_detail.spouse");
    }
    if (params.members.daughter == false) {
      _.unset(params, "members_detail.daughter_1");
    }
    if (params.members.son == false) {
      _.unset(params, "members_detail.son_1");
    }
  };

  onAnsChangeHandler = (e, ques, index) => {
    let setAnswer = {
      ...ques,
      answer: e.target.value ? e.target.value : "",
    };

    let data = this.state.questions;
    data.splice(index, 1, setAnswer);
    this.setState({
      ...this.state,
      questions: [...data],
    });
  };

  next = () => {
    const { params, eClinic, step, questions, isLabTestBook } = this.state;
    if (step < this.steps) {
      if (step == 1 && (params.max_members == 1 || eClinic || isLabTestBook)) {
        this.setState({ step: 3 });
      } else {
        if (
          (eClinic || params.max_members == 1 || isLabTestBook) &&
          step == 3
        ) {
          AppStore.dispatch({ type: "LOADING", loading: true });
          this.doSubmit();
        } else {
          if (step == 4 && questions.length == 0) {
            AppStore.dispatch({ type: "LOADING", loading: true });
            this.doSubmit();
          } else {
            this.setState({ step: step + 1 });
          }
        }
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: true });
      this.doSubmit();
    }
  };

  prev = () => {
    const { step } = this.state;
    const location = this.props?.history?.location?.state;
    if (location?.productId && step === 2) {
      this.props?.history?.push("/");
    }
    if (step === 3) {
      if (
        (location?.productId || this.props.products.length === 1) &&
        this.state.params.max_members == 1
      ) {
        this.props?.history?.push("/");
      }
    }
    if (
      step == 3 &&
      (this.state.eClinic || this.state.params.max_members == 1)
    ) {
      this.setState({ cancelPayment: false, step: 1 });
    } else {
      this.setState({ cancelPayment: false, step: step - 1 });
    }
  };

  doSubmit = async () => {
    const resellerPin = this.props.user?.basic?.pincode;
    const location = this.props?.history?.location?.state;
    const { params, isLabTestBook } = this.state;
    if (params.phone == location?.insuredMember?.[0]?.mobile) {
      _.set(params, "renew", true);
      _.set(params, "old_application_id", location?.application_id);
    }
    let data = { ...params };
    if (isLabTestBook) {
      if (!data.email) {
        data.email = "demo@abc.xyz";
      }
      if (!data.governmentIdType) {
        data.governmentIdType = "aadhar";
      }
      if (!data.governmentId) {
        data.governmentId = "000000000000";
      }
      if (!data.pincode) {
        if (resellerPin) {
          data.pincode = resellerPin;
        } else {
          data.pincode = "122001";
        }
      }
      if (!data.age) {
        data.dob = moment().subtract(18, "years").format("YYYY-MM-DD");
      } else {
        data.dob = moment().subtract(data.age, "years").format("YYYY-MM-DD");
      }
    }

    if (data?.members_detail?.coApplicants) {
      _.unset(data, "members_detail.coApplicants");
    }
    let trimedData = trimObjectValues(data);
    if (!this.state.isEmailRequired) {
      delete trimedData.email;
    }
    const response = await ApiPost("subscribe", {
      ...trimedData,
      medAnswers: this.state.questions,
    });
    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (this.state.eClinic) {
        if (response.data.bookNow) {
          toast.success("Appointment created successfully");
        } else {
          toast.error(
            "Appointment not created because Doctor not available at this time slot"
          );
        }
        this.props.history.replace({
          pathname: `/subscription/${response.data.application_id}`,
        });
      } else if (response.data?.policyDetails?.policy_id) {
        toast.success("Policy created successfully");
        this.props.history.replace({
          pathname: `/subscription/${response.data.application_id}`,
        });
      } else {
        this.props.history.replace({
          pathname: `/subscription-preview/${response.data.application_id}`,
        });
      }
    } else if (response.code === 307) {
      toast.info("server is busy");
      if (this.state.retryCount < this.state.maxRetries) {
        this.setState(
          (prevState) => ({ retryCount: prevState.retryCount + 1 }),
          () => {
            if (this.timeout.current) {
              clearTimeout(this.timeout.current);
            }
            this.timeout.current = setTimeout(() => {
              this.doSubmit(true);
            }, 3000);
          }
        );
      } else {
        AppStore.dispatch({ type: "LOADING", loading: false });
        toast.error("Max retry attempts reached. Please try again later.");
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      this.setState({ retryCount: 0 });
      toast.error(response?.message);
    }
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevState.step !== this.state.step) {
      this.setProgress();
    }
    if (prevProp.products.length != this.props.products.length) {
      if (this.props.products.length === 1) {
        const data = { target: { value: this.props.products[0].product_id } };
        this.onChangeHandler(data, "product");
        this.next();
      }
    }
  }

  componentDidMount(prevProps) {
    this.setProgress();
    const { params } = this.state;
    const location = this.props?.history?.location?.state;
    if (location) {
      if (location?.search_type == "name") {
        this.setState({
          ...this.state,
          params: {
            ...this.state.params,
            name: location.search,
          },
        });
      } else if (location?.search_type == "mobile") {
        this.setState({
          ...this.state,
          params: {
            ...this.state.params,
            phone: location.search,
          },
        });
      }
      if (location?.product_id) {
        let products = [];
        _.forEach(this.props.products, (product) => {
          products.push(product.id);
        });
        if (products.includes(location.product_id))
          _.set(params, "product", location.product_id);
        else _.set(params, "notExistingPlan", true);

        let son = 0;
        let daughter = 0;
        _.set(params, "pincode", location?.pincode);
        this.checkPin();
        if (location?.renewal_date) {
          let renewalDate = moment(location?.renewal_date).format("YYYY-MM-DD");
          _.set(params, "renewal_date", renewalDate);
        }
        _.forEach(location?.insuredMember, (item) => {
          if (item.relation.toLowerCase() === "self") {
            _.set(params, `members_detail.self.applicant`, true);
            _.set(params, "name", item?.name);
            _.set(params, "phone", item?.mobile);
            _.set(params, "email", item?.email_id);
            _.set(params, "dob", item?.dob);
            _.set(params, "gender", genderMatch(item?.gender?.toLowerCase()));
            let govIdType = governmentIdMatch(item?.gov_id_type?.toLowerCase());
            _.set(params, "governmentIdType", govIdType);
            _.set(params, "governmentId", item?.gov_id_number);
          }
          if (item.relation.toLowerCase() === "spouse") {
            _.set(params, "members.spouse", true);
            this.setOldInformation(item, "spouse");
          }
          if (item.relation.toLowerCase() === "daughter") {
            _.set(params, "members.daughter", true);
            if (daughter == 2) {
              _.set(params, "members_count.daughter", 3);
              this.setOldInformation(item, "daughter_3");
            }
            if (daughter == 1) {
              daughter++;
              _.set(params, "members_count.daughter", 2);
              this.setOldInformation(item, "daughter_2");
            }
            if (daughter == 0) {
              daughter++;
              this.setOldInformation(item, "daughter_1");
            }
          }
          if (item.relation.toLowerCase() === "son") {
            _.set(params, "members.son", true);
            if (son == 2) {
              _.set(params, "members_count.son", 3);
              this.setOldInformation(item, "son_3");
            }
            if (son == 1) {
              son++;
              _.set(params, "members_count.son", 2);
              this.setOldInformation(item, "son_2");
            }
            if (son == 0) {
              son++;
              this.setOldInformation(item, "son_1");
            }
          }
          if (item.relation.toLowerCase() === "self_parent_1") {
            _.set(params, "members.parents", true);
            this.setOldInformation(item, "self_parent_1");
          }
          if (item.relation.toLowerCase() === "self_parent_2") {
            _.set(params, "members.parents", true);
            _.set(params, "members_count.parents.self", 2);
            this.setOldInformation(item, "self_parent_2");
          }
          if (item.relation.toLowerCase() === "spouse_parent") {
            _.set(params, "members.parents.spouse", true);
            _.set(params, "members_count.parents.spouse", 1);
            this.setOldInformation(item, "spouse_parent_1");
          }
        });
        this.setState({ params: params });
      }
    }
    if (this.props.products.length === 1) {
      const product = this.props?.products[0];
      const data = { target: { value: product?.product_id } };
      this.onChangeHandler(data, "product");
      if (product?.product?.max_members === 1) {
        this.setState({ step: 3 });
      } else {
        this.next();
      }
    }
    if (location?.productId) {
      this.props.products?.map((elem) => {
        if (elem.product_id === location.productId) {
          const data = { target: { value: elem.product_id } };
          this.onChangeHandler(data, "product");
          if (elem?.product?.max_members === 1) {
            this.setState({ step: 3 });
          } else {
            this.setState({ step: 2 });
          }
        }
      });
    }
  }

  setOldInformation = (item, relation) => {
    const { params } = this.state;
    if (item?.member_type?.toLowerCase() == "secondary") {
      _.set(params, `members_detail.${relation}.coApplicant`, true);
    }
    _.set(params, `members_detail.${relation}.name`, item?.name);
    _.set(params, `members_detail.${relation}.phone`, item?.mobile);
    _.set(params, `members_detail.${relation}.email`, item?.email_id);
    _.set(params, `members_detail.${relation}.dob`, item?.dob);
    _.set(
      params,
      `members_detail.${relation}.gender`,
      genderMatch(item?.gender?.toLowerCase())
    );
    let govIdType = governmentIdMatch(item?.gov_id_type?.toLowerCase());
    _.set(params, `members_detail.${relation}.govtIDType`, govIdType);
    _.set(
      params,
      `members_detail.${relation}.govtIDNumber`,
      item?.gov_id_number
    );
    this.setState({ params: params });
  };

  setMemberChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params });
    if (params.members.spouse == false) {
      _.unset(params, "members_detail.spouse");
    }
    const location = this.props?.history?.location?.state;
    if (location?.insuredMember) {
      _.forEach(location.insuredMember, (item) => {
        if (key == "members.spouse" && item.relation.toLowerCase() === "spouse")
          this.setOldInformation(item, "spouse");
        if (
          key == "members.daughter" &&
          item.relation.toLowerCase() === "daughter"
        )
          this.setOldInformation(item, "daughter_1");
        if (key == "members.son" && item.relation.toLowerCase() === "son")
          this.setOldInformation(item, "son_1");
      });
    }
    if (params.members.daughter == false) {
      _.unset(params, "members_detail.daughter_1");
    }
    if (params.members.son == false) {
      _.unset(params, "members_detail.son_1");
    }
  };

  setProgress = () => {
    let progress = 0;
    if (this.state.eClinic) {
      switch (this.state.step) {
        case 1:
          progress = this.steps === 5 ? 20 : 25;
          break;
        case 2:
          progress = this.steps === 5 ? 40 : 50;
          break;
        case 3:
          if (this.state.eClinic) progress = 100;
          break;
        default:
          progress = this.steps === 5 ? 100 : 100;
      }
    } else {
      switch (this.state.step) {
        case 1:
          progress = this.steps === 5 ? 20 : 25;
          break;
        case 2:
          progress = this.steps === 5 ? 40 : 50;
          break;
        case 3:
          progress = this.steps === 5 ? 60 : 75;
          break;
        case 4:
          progress = this.steps === 5 ? 80 : 100;
          break;
        default:
          progress = this.steps === 5 ? 100 : 100;
      }
    }
    AppStore.dispatch({ type: "PROGRESS", progress: progress });
  };

  getQuestions = async () => {
    let answers = {};
    const response = await ApiGet(`questions`);
    if (response.status === "success") {
      _.forEach(response.data, (question) => {
        _.set(answers, `question_id${question.medicalQuestionId}`);
      });
      this.setState({ question_id: response.data, answers: answers });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, products, user } = state;
  return { loading: loading, products: products, user: user };
};

const SubscriptionConnect = connect(mapStateToProps)((props) => {
  return <Subscription {...props} />;
});

export default withRouter(SubscriptionConnect);
