import React, { createContext, useContext, useEffect } from "react";
import { FiChevronRight } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  DaughterIcon,
  FemaleIcon,
  MaleIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "./AppIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faImage,
  faQuestionCircle,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { getApiClaim, ROLE_STATUS_MAPPING } from "../lib/AppHelper";
import { saveAs } from "file-saver";
import { RouterContext } from "../App";
import { Placeholder } from "rsuite";
import { Tooltip } from "@mui/material";

export const AppRow = ({ children, className = "" }) => {
  return (
    <div
      className={["flex flex-wrap md:flex-row justify-between", className].join(
        " "
      )}
    >
      {children}
    </div>
  );
};

export const AppHeading = ({
  children,
  color = "black",
  className = "",
  size = "2xl",
  font = "medium",
  style,
}) => {
  return (
    <h3
      className={[`text-${size} font-${font} text-${color}`, className].join(
        " "
      )}
      style={style}
    >
      {children}
    </h3>
  );
};

export const AppCard = ({
  children,
  title = "",
  className = "",
  attrs = {},
  loader = false,
}) => {
  return (
    <div
      className={[
        `rounded-xl bg-primary-50 border border-primary p-4`,
        className,
      ].join(" ")}
      {...attrs}
    >
      {title ? <h5 className={`text-base mb-2`}>{title}</h5> : false}
      {loader ? (
        <Placeholder.Paragraph rows={2} rowHeight={8} rowMargin={10} active />
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export const AppSidebarMenu = ({ children }) => {
  return <div className="flex md:flex-col flex-row">{children}</div>;
};

export const AmountText = ({ amount }) => {
  amount = new Intl.NumberFormat("en-IN", {
    // maximumSignificantDigits: 4,
  }).format(amount);

  return (
    <div className="flex items-center">
      <BiRupee size={17} /> {amount}
    </div>
  );
};

export const AppSidebarMenuItem = ({ children, title = "", link = "/#" }) => {
  const active = useRouteMatch(link);

  return (
    <Link
      to={link}
      className={[
        `flex items-center justify-between px-4 py-6 hover:text-primary-600 focus:outline-none md:border-b last:border-0`,
        active ? "text-primary" : "",
      ].join(" ")}
    >
      <div className="flex-1 flex whitespace-nowrap">
        <div className="inline-block mr-3">{children}</div>
        <p className="text-sm font-semibold">{title}</p>
      </div>
      <span className="md:inline-block hidden">
        <FiChevronRight size={20} />
      </span>
    </Link>
  );
};

export const AppBackButton = ({
  to = "/",
  children,
  resetUrl = "",
  label = "Back",
  color = "text-red-500",
}) => {
  const history = useHistory();
  const route = useContext(RouterContext || createContext());

  if (route && route.from == route.to) {
    return (
      <div className={`px-6 ${color} mb-6 flex`}>
        <Link to={"/"}>
          <div className="flex items-center">
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{children || "Back to dashboard"}</p>
          </div>
        </Link>
      </div>
    );
  } else if (resetUrl) {
    return (
      <div className={`px-6 ${color} mb-6 flex`}>
        <Link to={route.from}>
          <div className="flex items-center">
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{label}</p>
          </div>
        </Link>
      </div>
    );
  } else
    return (
      <div className={`px-6 ${color} mb-6 flex`}>
        <div onClick={history.goBack} style={{ cursor: "pointer" }}>
          <div className="flex items-center">
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{label}</p>
          </div>
        </div>
      </div>
    );
};

export const AppStatusLabel = ({
  status,
  active = "Active",
  inactive = "Declined",
  pending = "Pending",
  waiting = "Waiting",
}) => {
  switch (status) {
    case 1:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2">
          {active}
        </span>
      );
    case 2:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-600  mr-2">
          {inactive}
        </span>
      );
    case 3:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-500 text-white  mr-2">
          {waiting}
        </span>
      );
    case 0:
    default:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800  mr-2">
          {pending}
        </span>
      );
  }
};

export const AppTeamStatus = ({ status }) => {
  switch (status) {
    case 1:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2">
          Active
        </span>
      );
    case 2:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-600 mr-2">
          Inactive
        </span>
      );
    case 3:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-500 text-white mr-2">
          Waiting
        </span>
      );
    case 0:
    default:
      return (
        <span className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-white-800 mr-2">
          Invitation Sent
        </span>
      );
  }
};

export const AppLabel = ({ title, color = "primary" }) => {
  let text_color = "text-primary-800";
  let bg_color = "bg-primary-100";

  switch (color) {
    case "blue":
      text_color = "text-blue-800";
      bg_color = "bg-blue-100";
      break;
    case "indigo":
      text_color = "text-indigo-800";
      bg_color = "bg-indigo-100";
      break;
    case "purple":
      text_color = "text-purple-800";
      bg_color = "bg-purple-100";
      break;
    case "pink":
      text_color = "text-pink-800";
      bg_color = "bg-pink-100";
      break;
    case "orange":
      text_color = "text-orange-800";
      bg_color = "bg-orange-100";
      break;
    case "teal":
      text_color = "text-teal-800";
      bg_color = "bg-teal-100";
      break;
    default:
      text_color = `text-${color}-800`;
      bg_color = `bg-${color}-100`;
      break;
  }

  return (
    <span
      className={`px-3 inline-flex text-xs leading-5 font-semibold mr-2 rounded-full ${text_color} ${bg_color}`}
    >
      {title}
    </span>
  );
};

export const MemberIcon = ({ member, size = 24 }) => {
  if (member.relation === "self" && member.gender === "m")
    return <MaleIcon size={size} />;

  if (member.relation === "self" && member.gender === "f")
    return <FemaleIcon size={size} />;

  if (member.relation === "spouse") return <RingIcon size={size} />;

  if (member.relation === "daughter") return <DaughterIcon size={size} />;

  if (member.relation === "son") return <SonIcon size={size} />;

  if (member.relation === "parent" || member.relation === "spouse_parent")
    return <ParentsIcon size={size} />;

  return false;
};

export const Tenor = ({ tenor }) => {
  const tenorSuffix = tenor > 0 ? "s" : "";
  if (tenor == 18) return "1.5 Years";
  else if (tenor == 1) return "1.5 Year";
  else if (tenor == 2) return "2 Years ";
  else return tenor + " Month" + tenorSuffix;

  return false;
};
const convertBase64ToFile = (base64String, fileName, type) => {
  let bstr = atob(base64String);
  let n = bstr.length;
  let uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  let file = new File([uint8Array], fileName, { type });
  return file;
};
export const downloadBase64Data = (
  base64String,
  fileName,
  type,
  download = true
) => {
  try {
    let file = convertBase64ToFile(base64String, fileName, type);
    if (download) saveAs(file, fileName);
    return file;
  } catch (e) {
    console.log("@@@@@@file", e);
  }
};

export const DocumentView = ({ item, status, isShowStatus = true }) => {
  const getData = async () => {
    getApiClaim(`docs/${item.id}`)
      .then((res) => {
        downloadBase64Data(
          res.data.encodedResource,
          res.data.document.documentName,
          res.data.mimeType
        );
      })
      .catch(() => {});
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-md border-dashed border-2 border-red-200">
      <div className="px-6 py-4">
        <span className="text-xl">
          <FontAwesomeIcon icon={faImage} className=""></FontAwesomeIcon>
        </span>
        <div className="mb-2 pt-2 text-gray-500 text-base">
          {item.document_name || item.documentName}
        </div>
        <div className="flex justify-between">
          <Tooltip title="View uploaded document" placement="right" arrow>
            <div
              className="text-xl"
              style={{ cursor: "pointer" }}
              onClick={getData}
            >
              <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
            </div>
          </Tooltip>

          {isShowStatus && (
            <div>
              <CouponAlert status={status} />
              <span style={{ fontSize: 14 }}>{status ? status : ""}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ClaimStatus = ({ status, style = {}, modifiedStyle = false }) => {
  let text_color = "text-primary-800";
  let bg_color = "bg-primary-100";

  switch (ROLE_STATUS_MAPPING[status]) {
    case "Queried":
      style = {
        ...style,
        color: "#F43985",
        fontWeight: "400",
        backgroundColor: "#F439851F",
      };
      break;
    case "Rejected":
      style = {
        ...style,
        color: "#A83CFD",
        fontWeight: "400",
        backgroundColor: "#CCA0EF1F",
      };
      break;
    case "DRAFT":
      style = {
        ...style,
        color: "#6E6E6E",
        fontWeight: "400",
        backgroundColor: "#8A8B971F",
      };
      break;
    case "In Progress":
      style = {
        ...style,
        color: "#CC7822",
        fontWeight: "400",
        backgroundColor: "#FEBE001F",
      };
      break;
    case "Approved":
      style = {
        ...style,
        color: "#CC7822",
        fontWeight: "400",
        backgroundColor: "#FEBE001F",
      };
      break;
    case "Settled":
      style = {
        ...style,
        color: "#008000",
        fontWeight: "400",
        backgroundColor: "#0080001F",
      };
      break;
    default:
      text_color = `text-primary-800`;
      bg_color = `bg-primary-100`;
      break;
  }

  return (
    <span
      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-xl ${text_color} ${bg_color} capitalize`}
      style={{ ...style }}
    >
      {modifiedStyle && (
        <span
          style={{
            color: `${text_color}`,
            fontWeight: "bolder",
            paddingRight: "4px",
          }}
        >
          {`\u25CF`}
        </span>
      )}

      {ROLE_STATUS_MAPPING[status] && ROLE_STATUS_MAPPING[status].toLowerCase()}
    </span>
  );
};

export const CouponAlert = ({ status, message }) => {
  let className = "text-primary-800";
  let icons = "";

  if (status == "APPROVE") {
    className = "text-green-600";
    icons = <FontAwesomeIcon size="lg" icon={faCheckCircle}></FontAwesomeIcon>;
  } else if (status == "REJECT") {
    className = "text-primary-600";
    icons = <FontAwesomeIcon size="lg" icon={faWindowClose}></FontAwesomeIcon>;
  } else {
    className = "text-yellow-600";
    icons = (
      <FontAwesomeIcon size="lg" icon={faQuestionCircle}></FontAwesomeIcon>
    );
  }

  return (
    <>
      <span className={className}>
        {icons} {message}
      </span>
    </>
  );
};
