import moment from "moment";

export const DateFilter = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "This week",
    value: "thisWeek",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Previous Week",
    value: "previousWeek",
  },
  {
    label: "Previous Month",
    value: "previousMonth",
  },
  {
    label: "Previous Year",
    value: "previousYear",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const salesPersonList = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => ({
    label: item?.name,
    value: item.id,
  }));
  return result;
};

export const ViewData = (data) => {
  if (data?.length === 0) return;
  let result = Object.keys(data?.viewedBy)?.map((key) => ({
    label: key?.toLowerCase().replace(" by", " date"),
    value: data?.viewedBy[key],
  }));
  return result;
};

export const PaymentPartner = [
  {
    label: "Hospido",
    value: "HOSPIDO",
  },
  {
    label: "Doc",
    value: "DOC",
  },
];
export const WeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label);

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  return [formattedStartDate, currentDate.format("YYYY-MM-DD")];
};

export const LastWeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label).subtract(1, label);

  const endDate = currentDate.clone().startOf(label).subtract(1, "day");
  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");
  return [formattedStartDate, formattedEndDate];
};
