import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";

const useClaimsPagination = ({
  request,
  data,
  limit = 15,
  filterValues = {},
  endPoint,
  extraData,
  handleGetSearch,
  dataType,
  filter,
  dateFilter,
}) => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState(data);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sortBy, setSortBy] = useState("desc");
  const [sequenceType, setSequenceType] = useState("");
  const lastFilter = useRef(null);
  const lastStatus = useRef(null);

  const fetchData = async (pg = null) => {
    const { dateType, to, from, datePage } = dateFilter;

    if (dateType === "custom_date" && (to === null || from === null)) return;

    if (dateFilter?.to === undefined || dateFilter?.from === undefined) return;
    const start = limit * (pg ? pg - 1 : page - 1);
    if (pg || datePage) setPage(pg || datePage);
    if (!data) {
      try {
        let dynamicSearchParam = {};
        if (filterValues.search_type && filterValues.search) {
          dynamicSearchParam[filterValues.search_type] = filterValues.search;
        }
        let status = filter === null ? "" : filter;
        AppStore.dispatch({ type: "LOADING", loading: true });
        const res = await request(endPoint, {
          start,
          limit,
          page: pg ? pg : datePage ? datePage : page,
          from: dateFilter.from,
          to: dateFilter.to,
          specification_type: dateFilter?.specificationType
            ? dateFilter?.specificationType
            : "",
          ...dynamicSearchParam,
          status,
          sort_by: sortBy,
          sequence_type: sequenceType,
        });
        lastStatus.current = status;
        if (res.status === "error") {
          toast.error(res.message);
          setErrorMessage(res.message);
        } else {
          if (res.data.total) {
            setTotalCount(res.data.total);
          }
          handleGetSearch && handleGetSearch(res.data.total);
          setTableData(res.data.results || res.data);
        }
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        AppStore.dispatch({ type: "LOADING", loading: false });
        lastFilter.current = filterValues;
      }
    } else {
      const newData = [...data];
      const update = newData.slice(start, limit * page);
      setTotalCount(data.length);
      setTableData(update);
    }
  };

  useEffect(() => {
    if (lastStatus.current !== filter) fetchData(1);
    else if (
      JSON.stringify(lastFilter.current) !== JSON.stringify(filterValues)
    )
      fetchData(1);
    else {
      fetchData();
    }
  }, [filterValues, dateFilter, sortBy]);

  const handleChangePage = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchData(pg);
  };

  const changeSortDirection = (column) => {
    setSortBy((prev) => (prev === "asc" ? "desc" : "asc"));
    setSequenceType(column);
    setPage(1);
  };

  return {
    tableData,
    totalCount,
    page,
    errorMessage,
    handleChangePage,
    fetchData,
    changeSortDirection,
    sortBy,
  };
};

export default useClaimsPagination;
