import React, { memo, useEffect, useRef, useState } from "react";
import { Pagination } from "rsuite";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppStore } from "../../../lib/AppStore";
import { truncateString } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUserPlus,
  faCheckCircle,
  faUser,
  faIdCard,
  faPhoneAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

const PaginationData = memo(
  ({
    request,
    data,
    limit = 15,
    pagination = true,
    filterValues = {},
    loading,
    endPoint,
    extraData,
    renderItem,
    dataType,
    handleGetSearch,
    pageType = "",
    permissions,
  }) => {
    if (limit < 1) {
      limit = 1;
    }
    const lastFilter = useRef(null);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [tableData, setTableData] = useState(data);
    const [errorMessage, setErrorMessage] = useState();

    const handelChangePage = (pg) => {
      if (page === pg) return;
      setPage(pg);
      fetchData(pg);
    };

    const fetchData = async (pg = null) => {
      const start = limit * (pg ? pg - 1 : page - 1);
      if (pg) setPage(pg);
      if (!data) {
        try {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const res = await request(endPoint, {
            start,
            limit,
            page: pg ? pg : page,
            ...filterValues,
          });

          if (res.status == "error") {
            setErrorMessage(res.message);
          } else {
            if (res.data.total) {
              setTotalCount(res.data.total);
            }
            handleGetSearch && handleGetSearch(res.data.total);
            if (res.data.results) {
              setTableData(res.data.results);
            } else {
              setTableData(res.data);
            }
          }
        } catch (err) {
        } finally {
          AppStore.dispatch({ type: "LOADING", loading: false });
          lastFilter.current = filterValues;
        }
      } else {
        const newData = [...data];
        const update = newData.slice(start, limit * page);
        setTotalCount(data?.length);
        setTableData(update);
      }
    };

    useEffect(() => {
      if (JSON.stringify(lastFilter.current) !== JSON.stringify(filterValues))
        fetchData(1);
      else {
        fetchData();
      }
    }, [setTableData, setTotalCount, request, data, filterValues]);

    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        className="mt-2"
      >
        {tableData && tableData?.length === 0 ? (
          <div className="text-center">
            <p className="text-xl">You do not have any item</p>
          </div>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-between lg:justify-center">
            {tableData &&
              tableData.length >= 0 &&
              tableData.map((item, index) => {
                if (renderItem) {
                  return renderItem(item);
                }
                return (
                  <div
                    className="my-4 px-1 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 sm:w-full max-w-96"
                    key={index}
                  >
                    <article
                      className="overflow-hidden rounded-lg shadow-md"
                      style={{ height: pageType == "leads" ? 170 : 256 }}
                    >
                      {pageType == "expired" || pageType == "expiring-soon" ? (
                        <div className="flex flex-col justify-between h-full">
                          <header className="flex items-center justify-between leading-tight pl-4">
                            <div>
                              <p className="text-lg font-bold capitalize pt-2">
                                {truncateString(
                                  item.customer_name
                                    ? item.customer_name?.toLowerCase()
                                    : item.name?.toLowerCase(),
                                  20,
                                  "Member Empty"
                                )}
                              </p>
                              <p className="text-sm font-bold pt-2">
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  28,
                                  "Product Empty"
                                )}
                              </p>
                            </div>
                            <p className="text-grey-darker text-sm pt-1">
                              <div className="pr-3">
                                <span className="text-green-600">
                                  {moment(
                                    item.start_date
                                      ? item.start_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>{" "}
                                <br></br>
                                <span className="text-red-600">
                                  {" "}
                                  {moment(
                                    item.end_date
                                      ? item.end_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>
                              </div>
                            </p>
                          </header>

                          <body
                            className={`flex items-center justify-between leading-tight p-4  ${
                              item.stage != "DRAFT" ? "pt-4" : "md:p-3"
                            }`}
                          >
                            <div className="text-sm text-clip overflow-hidden ...">
                              {item?.gov_id_type && item?.gov_id_number && (
                                <p className="flex items-center">
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faIdCard}
                                      className="text-gray-400"
                                    ></FontAwesomeIcon>
                                    &nbsp;
                                    {item?.gov_id_type ? item?.gov_id_type : ""}
                                  </span>
                                  &nbsp;:&nbsp;
                                  {typeof item?.gov_id_number === "string"
                                    ? item?.gov_id_number.length > 9
                                      ? new Array(
                                          item?.gov_id_number?.length - 3
                                        ).join("x") +
                                        item?.gov_id_number
                                          ?.substr(
                                            item?.gov_id_number?.length - 4,
                                            4
                                          )
                                          .toUpperCase()
                                      : item?.gov_id_number?.toUpperCase()
                                    : item?.gov_id_number}
                                </p>
                              )}

                              {item?.mobile && (
                                <p className="pt-2 flex items-center">
                                  <FontAwesomeIcon
                                    icon={faPhoneAlt}
                                    className="text-green-500"
                                  ></FontAwesomeIcon>
                                  &nbsp;Phone :&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      width: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <a
                                      className="no-underline text-black"
                                      href={`tel:+91${item?.mobile}`}
                                    >
                                      {item?.mobile}
                                    </a>
                                  </span>
                                </p>
                              )}
                              {item?.loan_code && (
                                <p className="pt-2 flex items-center">
                                  <FontAwesomeIcon
                                    icon={faCreditCard}
                                    className="text-blue-500"
                                  ></FontAwesomeIcon>
                                  &nbsp;Loan&nbsp;Code&nbsp;:&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      width: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {truncateString(item?.loan_code, 20)}
                                  </span>
                                </p>
                              )}
                              {permissions &&
                              permissions?.show_reseller_name_on_member_card
                                ? item?.reseller_name && (
                                    <p className="pt-2 flex items-center capitalize">
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-indigo-500"
                                      ></FontAwesomeIcon>
                                      &nbsp; Partner Name :&nbsp;
                                      {truncateString(
                                        item?.reseller_name?.toLowerCase(),
                                        18,
                                        "Partner name empty"
                                      )}
                                    </p>
                                  )
                                : null}
                            </div>
                          </body>
                          <div className="flex flex-nowrap justify-between px-4 pb-2 ">
                            <Link to={`/subscription/${item.application_id}`}>
                              <button
                                type="button"
                                className="text-red-500 bg-white border border-gray-400 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded text-sm px-3 py-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 self-end"
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="mr-2"
                                ></FontAwesomeIcon>
                                View
                              </button>
                            </Link>
                            {permissions?.renew_policy && (
                              <>
                                {item?.newPolicyCreated == "policy" && (
                                  <Link
                                    to={`/subscription/${item.new_application_id}`}
                                  >
                                    <button
                                      type="button"
                                      className="text-green-600 bg-white border border-gray-400 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded text-sm px-3 py-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 self-end"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-2"
                                      ></FontAwesomeIcon>
                                      Renewed
                                    </button>
                                  </Link>
                                )}
                                {item?.newPolicyCreated == "lead" && (
                                  <Link
                                    to={`/subscription/${item.new_application_id}`}
                                  >
                                    <button
                                      type="button"
                                      className="text-orange-500 bg-white border border-gray-400 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded text-sm px-3 py-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 self-end"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mr-2"
                                      ></FontAwesomeIcon>
                                      Continue to payment
                                    </button>
                                  </Link>
                                )}
                                {item?.newPolicyCreated == null && (
                                  <Link
                                    to={{
                                      pathname: "/subscribe",
                                      state: item,
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="text-red-500 bg-white border border-gray-400 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded text-sm px-3 py-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 self-end"
                                    >
                                      <FontAwesomeIcon
                                        icon={faUserPlus}
                                        className="mr-2"
                                      ></FontAwesomeIcon>
                                      Renew
                                    </button>
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={`/subscription-preview/${item?.application_id}`}
                        >
                          <header className="flex items-center justify-between leading-tight pl-4">
                            <div>
                              <p className="text-lg font-bold capitalize pt-2">
                                {truncateString(
                                  item.customer_name
                                    ? item.customer_name?.toLowerCase()
                                    : item.name?.toLowerCase(),
                                  20,
                                  "Member Empty"
                                )}
                              </p>
                              <p className="text-sm font-bold pt-2">
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  28,
                                  "Product Empty"
                                )}
                              </p>
                            </div>
                            {pageType == "leads" ? (
                              <div className="pr-3">
                                <span className="">
                                  {moment(
                                    item.start_date
                                      ? item.start_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>
                                {item?.renew_policy && (
                                  <p className="text-red-500">Renewal</p>
                                )}
                              </div>
                            ) : (
                              <p className="text-grey-darker text-sm">
                                <div className="pr-3">
                                  <span className="text-green-600">
                                    {moment(
                                      item.start_date
                                        ? item.start_date
                                        : item.application_date
                                    ).format("DD, MMM YYYY")}
                                  </span>{" "}
                                  <br></br>
                                  <span className="text-red-600">
                                    {" "}
                                    {moment(
                                      item.end_date
                                        ? item.end_date
                                        : item.application_date
                                    ).format("DD, MMM YYYY")}
                                  </span>
                                </div>
                              </p>
                            )}
                          </header>

                          <body
                            className={`flex items-center justify-between leading-tight p-4  ${
                              item.stage != "DRAFT" ? "pt-4" : "md:p-3"
                            }`}
                          >
                            <div className="text-sm text-clip overflow-hidden ...">
                              {item?.gov_id_type && item?.gov_id_number && (
                                <p className="flex items-center">
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {/* <FontAwesomeIcon
                                      icon={faIdCard}
                                      className="text-gray-400"
                                    ></FontAwesomeIcon>
                                    &nbsp; */}
                                    {item?.gov_id_type ? item?.gov_id_type : ""}
                                  </span>
                                  &nbsp;:&nbsp;
                                  {typeof item?.gov_id_number === "string"
                                    ? item?.gov_id_number.length > 9
                                      ? new Array(
                                          item?.gov_id_number?.length - 3
                                        ).join("x") +
                                        item?.gov_id_number
                                          ?.substr(
                                            item?.gov_id_number?.length - 4,
                                            4
                                          )
                                          .toUpperCase()
                                      : item?.gov_id_number?.toUpperCase()
                                    : item?.gov_id_number}
                                </p>
                              )}
                              {item?.mobile && (
                                <p className="pt-2 flex items-center">
                                  {/* <FontAwesomeIcon
                                    icon={faPhoneAlt}
                                    className="text-green-500"
                                  ></FontAwesomeIcon>
                                    &nbsp; */}
                                  Phone :&nbsp;
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      width: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <a
                                      className="no-underline text-black"
                                      href={`tel:+91${item?.mobile}`}
                                    >
                                      {item?.mobile}
                                    </a>
                                  </span>
                                </p>
                              )}
                              {/* <p className="pt-2 flex items-center">
                                 <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="text-red-500"
                                ></FontAwesomeIcon>
                                  &nbsp; 
                                  Plan :&nbsp;
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  30,
                                  "Product Empty"
                                )}
                              </p> */}
                              {permissions &&
                              permissions?.show_reseller_name_on_member_card
                                ? item?.reseller_name && (
                                    <p className="pt-2 flex items-center capitalize">
                                      {/* <FontAwesomeIcon
                                        icon={faUser}
                                        className="text-indigo-500"
                                      ></FontAwesomeIcon>
                                      &nbsp; */}
                                      Partner Name :&nbsp;
                                      {truncateString(
                                        item?.reseller_name?.toLowerCase(),
                                        18,
                                        "Partner name empty"
                                      )}
                                    </p>
                                  )
                                : null}
                            </div>
                          </body>
                        </Link>
                      )}
                    </article>
                  </div>
                );
              })}
          </div>
        )}
        {pagination && tableData && tableData?.length > 0 ? (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={limit}
              activePage={page}
              onChangePage={(page) => handelChangePage(page)}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default PaginationData;
